



















































































































































































































































































































































































































































































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
//@ts-ignore
import { VueGoodTable } from "vue-good-table";
import {
  BillViewModel,
  UserRole,
  PNote,
  CommonData,
  VetAndClear,
  CheckType,
  BillStatus
} from "@/models";
import ModalNoticeEdit from "@/components/Modal/ModalNoticeEdit.vue";
import BillService from "@/services/BillService";
import ContractService from "@/services/ContractService";
//@ts-ignore
import VueTagsInput from "@johmun/vue-tags-input";
import DatePickerLang from "@/helpers/DatePickerLang";
import ModalContractDetailTemp from "@/views/Contract/DetailTemp/ModalContractDetailTemp.vue";

@Component({
  components: {
    VueGoodTable,
    ModalNoticeEdit,
    VueTagsInput,
    ModalContractDetailTemp
  }
})
export default class Dashboard extends Vue {
  @Prop() private isLoading!: boolean;
  @Prop() private listCompany!: CommonData[];
  @Prop() private listOrigin!: CommonData[];
  @Prop() private listSupplier!: CommonData[];
  @Prop() private listCarrier!: CommonData[];
  @Prop() private trackingRows!: BillViewModel[];
  private rows: BillViewModel[] = [];
  private columns: any[] = [];
  private searchOptions: any = {
    enabled: true,
    skipDiacritics: true,
    placeholder: "nhập gì đó..."
  };
  private sortOptions: any = {
    enabled: true
  };
  private paginationOptions: any = {
    enabled: true,
    setCurrentPage: 1,
    perPage: 5,
    perPageDropdown: [5, 10, 20, 50, 100],
    position: "bottom",
    jumpFirstOrLast: true,
    nextLabel: this.$t("common.table.next"),
    prevLabel: this.$t("common.table.prev"),
    firstLabel: "Đầu",
    lastLabel: "Cuối",
    rowsPerPageLabel: "Dòng/trang",
    ofLabel: "trên",
    pageLabel: "trang",
    allLabel: "Tất cả"
  };

  //filter
  private tableFilter: any = {
    company: "",
    supplier: "",
    carrier: "",
    sortBy: "",
    sortType: ""
  };

  private selectedRow: BillViewModel = new BillViewModel();
  private selectedPNote: PNote = new PNote();
  private zIndex: number = 10;
  //
  private contractId: string = "";
  private billId: string = "";
  private eBillStatus: any = BillStatus;

  //set status for vet and clear
  private vetClear: VetAndClear = new VetAndClear();
  private prevent: boolean = false;
  private eCheckType: any = CheckType;
  private datePickProp: DatePickerLang = new DatePickerLang();

  //role
  private userRole: UserRole = this.$store.state.user.role;
  private eUserRole = UserRole;

  //advance search
  private filterOption: FilterOption = new FilterOption();

  created() {
    this.loadTableFilter();

    this.$bus.$on("loadTableProgressFilter", () => {
      this.loadTableFilter();
      this.setTableColumn();
    });
  }

  destroyed() {
    this.$bus.$off("loadTableProgressFilter");
  }

  loadTableFilter() {
    if (sessionStorage.getItem("table-progress-filter")) {
      this.tableFilter = JSON.parse(
        sessionStorage.getItem("table-progress-filter") as string
      );
      if (this.tableFilter.sortBy && this.tableFilter.sortType) {
        this.sortOptions.initialSortBy = {
          field: this.tableFilter.sortBy,
          type: this.tableFilter.sortType
        };
      }

      if (this.tableFilter.perPage) {
        this.paginationOptions.perPage = this.tableFilter.perPage;
      }

      if (this.tableFilter.page) {
        this.paginationOptions.setCurrentPage = this.tableFilter.page;
      }
    } else {
      this.tableFilter = {
        company: "",
        supplier: "",
        carrier: "",
        sortBy: "",
        sortType: ""
      };
    }
  }

  setTableFilter() {
    sessionStorage.setItem(
      "table-progress-filter",
      JSON.stringify(this.tableFilter)
    );
  }

  @Watch("isLoading")
  setTrackingRows() {
    this.rows = this.trackingRows;
    setTimeout(() => {
      clearTimeout();
      (this.$refs
        .tableContractProgress as any).currentPage = this.paginationOptions.setCurrentPage;
    }, 1);
  }

  onSortChange(params: any) {
    this.tableFilter.sortBy = params[0].field;
    this.tableFilter.sortType = params[0].type;
    this.setTableFilter();
  }

  onPageChange(params: any) {
    this.tableFilter.page = params.currentPage;
    this.tableFilter.perPage = params.currentPerPage;
    this.setTableFilter();
  }

  onColumnFilter(params: any) {
    this.tableFilter.company = params.columnFilters.company;
    this.tableFilter.supplier = params.columnFilters.supplier;
    this.tableFilter.carrier = params.columnFilters.carrier;
    this.setTableFilter();
  }

  vetClearDDShow(row: BillViewModel, type: CheckType) {
    this.selectedRow = row;
    this.vetClear = new VetAndClear({
      billId: row.id,
      status: type === CheckType.VET ? row.vetCheck : row.clearCheck,
      type: type,
      date: this.$moment(
        type === CheckType.VET ? row.vetDate : row.clearDate
      ).toDate()
    });
  }

  vetClearDDHide(event: any) {
    if (this.prevent) {
      event.preventDefault();
      this.prevent = false;
    }
  }

  preventDropdownClose() {
    this.prevent = true;
  }

  updateVetClear(isVet: boolean, id: number) {
    const currentVetClear = `vetAndClearDD-CP-${isVet ? "vet" : "clear"}-${id}`;
    const format = "DD-MM-YYYY HH:mm:ss";
    (this.$refs as any)[currentVetClear].hide(true);
    this.vetClear.date = this.vetClear.date
      ? this.$moment(this.vetClear.date).format(format)
      : null;
    BillService.vetCheck(this.vetClear).then(() => this.$emit("reCallData")).then(()=>{
      this.loadTableFilter();
    });
  }

  /**
   * Hanlge cbx for compay andy payment
   */
  handleSelectBoxChange(index: number, cb: ECombobox) {
    switch (cb) {
      case ECombobox.ORIGIN:
        this.filterOption.origin = this.listOrigin[index];
        break;
      case ECombobox.COMPANY:
        this.filterOption.company = this.listCompany[index];
        break;
    }
  }

  formatCurrency(num: number) {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD"
    }).format(num);
  }

  rowStyleClassFn(row: BillViewModel) {
    return row.id ? "" : "ct-bg-pink";
  }

  syncScroll(event: any, index: number) {
    const products: any = this.$refs[`productsVM${index}`];
    // const prices: any = this.$refs[`pricesVM${index}`];
    products.scrollTop = event.target.scrollTop;
    // prices.scrollTop = event.target.scrollTop;
  }

  handleRowNotice(row: BillViewModel) {
    this.selectedRow = row;
    if (
      this.userRole === UserRole.GUEST ||
      this.userRole === UserRole.ACCOUNTANT
    )
      return;
    this.$bvModal.show("list-contract-progress-notice");
  }

  submitNewNotice(newNotice: string) {
    BillService.updateBillNotice(this.selectedRow.id, newNotice)
      .then(res => {
        if (res.status === 200) {
          //toast
          this.$swal.fire({
            icon: "success",
            title: "Đổi thành công",
            position: "bottom-end",
            toast: true,
            showConfirmButton: false,
            timer: 1500
          });

          //update table data
          this.rows[this.selectedRow.originalIndex].notice = newNotice;
        }
      })
      .catch(() => {
        this.$swal.fire({
          icon: "error",
          title: "Đổi thất bại",
          position: "bottom-end",
          toast: true,
          showConfirmButton: false,
          timer: 1500
        });
      });
  }

  handleRowPNote(row: BillViewModel) {
    this.selectedRow = row;
    if (this.userRole !== UserRole.ADMIN) return;
    ContractService.getPNote(row.contractId).then(res => {
      this.selectedPNote = res.data;
      setTimeout(() => {
        this.$bvModal.show("list-dashboard-pnote");
      }, 10);
    });
  }

  openModalDocument(row: BillViewModel) {
    this.selectedRow = row;
    this.$emit("openDocument", { contractId: row.contractId, billId: row.id });
  }

  submitNewPNote(newPNote: string) {
    this.selectedPNote.pnote = newPNote;
    ContractService.savePNote(this.selectedPNote)
      .then(res => {
        if (res.status === 200) {
          //toast
          this.$swal.fire({
            icon: "success",
            title: "Đổi thành công",
            position: "bottom-end",
            toast: true,
            showConfirmButton: false,
            timer: 1500
          });
        }
      })
      .catch(() => {
        this.$swal.fire({
          icon: "error",
          title: "Đổi thất bại",
          position: "bottom-end",
          toast: true,
          showConfirmButton: false,
          timer: 1500
        });
      });
  }

  undoContract(row: BillViewModel) {
    if (this.userRole !== UserRole.ADMIN) return;
    ContractService.undoContractsNoBill(row.contractId)
      .then(() => {
        //toast
        this.$swal.fire({
          icon: "success",
          title: "Hoàn tác thành công",
          position: "bottom-end",
          toast: true,
          showConfirmButton: false,
          timer: 1500
        });

        //recall list contract new
        this.$bus.$emit("createContract");

        //recall list bill
        this.$emit("reCallData");
        this.loadTableFilter();
      })
      .catch(() => {
        this.$swal.fire({
          icon: "error",
          title: "Hoàn tác thất bại",
          position: "bottom-end",
          toast: true,
          showConfirmButton: false,
          timer: 1500
        });
      });
  }

  changeToDone(row: BillViewModel) {
    if (this.userRole !== UserRole.ADMIN) return;

    const callApi = () => {
      BillService.setBillDone(row.id)
        .then(res => {
          if (res.status === 200) {
            //toast
            this.$swal.fire({
              icon: "success",
              title: "Đổi thành công",
              position: "bottom-end",
              toast: true,
              showConfirmButton: false,
              timer: 1500
            });
          }
          this.$emit("reCallData");
          this.loadTableFilter();
        })
        .catch(() => {
          this.$swal.fire({
            icon: "error",
            title: "Đổi thất bại",
            position: "bottom-end",
            toast: true,
            showConfirmButton: false,
            timer: 1500
          });
        });
    };

    this.$swal
      .fire({
        icon: "question",
        title: `Bill ${row.billNo} hoàn thành?`,
        confirmButtonColor: "danger",
        cancelButtonText: "Hủy",
        showCancelButton: true
      })
      .then(res => {
        if (res.isConfirmed) {
          callApi();
        }
      });
  }

  deleteProgressBill(row: BillViewModel) {
    if (this.userRole !== UserRole.ADMIN) return;

    this.$swal({
      icon: "question",
      title: `Xóa bill ${row.billNo} của hợp đồng ${row.contractCode}?`,
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonText: "Xóa",
      cancelButtonText: "Hủy"
    }).then(result => {
      if (result.isConfirmed) {
        BillService.deleteProgressBill(row.id)
          .then(() => {
            //toast
            this.$swal.fire({
              icon: "success",
              title: "Xóa thành công",
              position: "bottom-end",
              toast: true,
              showConfirmButton: false,
              timer: 1500
            });
            this.$emit("reCallData");
            this.loadTableFilter();
          })
          .catch(() => {
            this.$swal.fire({
              icon: "error",
              title: "Xóa thất bại",
              position: "bottom-end",
              toast: true,
              showConfirmButton: false,
              timer: 1500
            });
          });
      }
    });
  }

  deleteProgressContract(row: BillViewModel) {
    if (this.userRole !== UserRole.ADMIN) return;

    this.$swal({
      icon: "question",
      title: `Xóa hợp đồng ${row.contractCode}?`,
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonText: "Xóa",
      cancelButtonText: "Hủy"
    }).then(result => {
      if (result.isConfirmed) {
        ContractService.deleteByContractId(row.contractId)
          .then(() => {
            //toast
            this.$swal.fire({
              icon: "success",
              title: "Xóa thành công",
              position: "bottom-end",
              toast: true,
              showConfirmButton: false,
              timer: 1500
            });
            this.$emit("reCallData");
            this.loadTableFilter();
          })
          .catch(() => {
            this.$swal.fire({
              icon: "error",
              title: "Xóa thất bại",
              position: "bottom-end",
              toast: true,
              showConfirmButton: false,
              timer: 1500
            });
          });
      }
    });
  }

  openModalContractDetail(contractId: string, billId: string) {
    this.contractId = contractId;
    this.billId = billId;
    this.$bvModal.show("modal-contract-progress-detail");
  }

  openModalContractDetailTemp(contractId: string, billId: string) {
    this.contractId = contractId;
    this.billId = billId;
    this.$bvModal.show("modal-contract-progress-detail-temp");
  }

  onSortDate(x: any, y: any, col: any, rowX: any, rowY: any) {
    // x - row1 value for column
    // y - row2 value for column
    // col - column being sorted
    // rowX - row object for row1
    // rowY - row object for row2

    let xVal = this.$moment(x, '"DD MMM YY"');
    let yVal = this.$moment(y, '"DD MMM YY"');
    if (!xVal.isValid() && !yVal.isValid()) return 0;
    if (!xVal.isValid()) return -1;
    if (!yVal.isValid()) return 1;
    return xVal.isBefore(yVal) ? -1 : !xVal.isBefore(yVal) ? 1 : 0;
  }

  toggleDropdown(row: BillViewModel) {
    document
      .getElementById(`bill-id-${row.contractId}-${row.id}`)
      ?.parentElement?.setAttribute(
        "style",
        `z-index: ${this.zIndex} !important;`
      );
    this.zIndex++;
  }

  @Watch("listCompany")
  @Watch("listSupplier")
  @Watch("listCarrier")
  setTableColumn() {
    const col1 = [
      {
        label: "#",
        field: "id",
        hidden: true
      },
      {
        label: "Mã HĐ",
        tooltip: "Mã hợp đồng",
        width: "100px",
        field: "contractCode"
      },
      {
        label: "Containers",
        tooltip: "Số container",
        width: "100px",
        field: "containers"
      },
      {
        label: "CT",
        tooltip: "Công ty",
        field: "company",
        width: "55px",
        filterOptions: {
          enabled: true,
          filterDropdownItems: this.listCompany.map(item => item.code),
          filterValue: this.tableFilter.company,
          placeholder: "Chưa chọn",
          styleClass: "contract-table-filter-select p-1",
          filterFn: (data: string, filterString: string) => {
            return data === filterString;
          }
        },
        sortable: false
      },
      {
        label: "NCC",
        field: "supplier",
        width: "55px",
        filterOptions: {
          enabled: true,
          filterDropdownItems: this.listSupplier.map(item => item.code).sort(),
          filterValue: this.tableFilter.supplier,
          placeholder: "Tất cả",
          styleClass: "contract-table-filter-select p-1",
          filterFn: (data: string, filterString: string) => {
            return data === filterString;
          }
        },
        sortable: false
      },
      {
        label: "Hãng tàu",
        field: "carrier",
        width: "55px",
        filterOptions: {
          enabled: true,
          filterDropdownItems: this.listCarrier.map(item => item.code).sort(),
          filterValue: this.tableFilter.carrier,
          placeholder: "Tất cả",
          styleClass: "contract-table-filter-select p-1",
          filterFn: (data: string, filterString: string) => {
            return data === filterString;
          }
        },
        sortable: false
      },
      {
        label: "Bill",
        field: "billNo",
        width: "80px"
      },
      {
        label: "Invoice",
        field: "invoiceNo",
        width: "80px"
      },
      {
        label: "ETD/DP",
        field: "etd",
        width: "98px",
        sortFn: this.onSortDate
      },

      // {
      //   label: "ETP/TP",
      //   field: "eta",
      //   width: "98px",
      //   sortFn: this.onSortDate
      // },
      {
        label: "ETA/FP",
        field: "etf",
        width: "98px",
        sortFn: this.onSortDate
      },
      {
        label: "Mặt hàng",
        field: "productsVM",
        tdClass: "w---1 mw--30",
        sortable: false
      },
      {
        label: "ĐGHQ",
        tooltip: "đơn giá hải quan",
        field: "valuesVM",
        tdClass: "text-align-end",
        width: "80px",
        sortable: false
      }
    ];
    // if (this.userRole !== UserRole.ACCOUNTANT) {
    //   col1.push({
    //     label: "ĐGTT",
    //     tooltip: "đơn giá thực tế",
    //     field: "pricesVM",
    //     tdClass: "text-align-end",
    //     width: "80px",
    //     sortable: false
    //   });
    // }

    const col2 = [
      {
        label: "Tổng",
        field: "total",
        tdClass: "text-align-end",
        width: "80px",
        sortable: false
      },
      {
        label: "Kiểm dịch",
        field: "vetCheck",
        width: "98px",
        sortable: false
      },
      {
        label: "Thông quan",
        field: "clearCheck",
        width: "98px",
        sortable: false
      },
      {
        label: "",
        field: "status",
        sortable: false,
        tdClass: "sticky-column-2",
        thClass: "sticky-column-2",
        width: "55px"
      }
    ];
    this.columns = [...col1, ...col2];
  }
}

enum ActionType {
  DETAIL,
  UPDATE,
  STATUS
}

export enum ECombobox {
  ORIGIN,
  COMPANY
}

class FilterOption {
  etaFrom: Date | null = null;
  etaTo: Date | null = null;
  signedFrom: Date | null = null;
  signedTo: Date | null = null;
  vetFrom: Date | null = null;
  vetTo: Date | null = null;
  delivery: boolean = true;
  done: boolean = false;
  origin: CommonData = new CommonData();
  company: CommonData = new CommonData();
}
