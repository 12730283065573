







import { Component, Vue } from "vue-property-decorator";
import FormCreateContract from "@/views/Contract/ModalCreate/FormCreateContract.vue";
import ModalDocument from "@/components/Modal/ModalDocument.vue";

@Component({ components: { FormCreateContract, ModalDocument } })
export default class ModalCreateContract extends Vue {
  private contractId: number = -1;

  handleOpenModalDocument(contractId: number) {
    this.contractId = contractId;
    setTimeout(() => {
      this.$bvModal.show("modal-document");
    }, 100);
  }
}
