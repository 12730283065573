











































































































































































































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
// @ts-ignore
import { VueGoodTable } from "vue-good-table";
import { CommonData, ContractViewModel, NewContract, UserRole } from "@/models";
import ModalNoticeEdit from "@/components/Modal/ModalNoticeEdit.vue";
import ContractService from "@/services/ContractService";
import FormCreateContract from "@/views/Contract/ModalCreate/FormCreateContract.vue";

@Component({
  components: { VueGoodTable, ModalNoticeEdit, FormCreateContract }
})
export default class ListContractNew extends Vue {
  @Prop({ default: [] }) private listCompany!: CommonData[];
  @Prop({ default: [] }) private listSupplier!: CommonData[];
  @Prop({ default: [] }) private listPort!: CommonData[];
  @Prop({ default: [] }) private listOrigin!: CommonData[];
  private isLoading: boolean = true;
  private selectedNewContact: ContractViewModel = new ContractViewModel();
  private selectedContract: NewContract = new NewContract();
  private eActionType: any = ActionType;
  private zIndex: number = 10;
  private columns: any[] = [];
  private rows: ContractViewModel[] = [];

  private searchOptions: any = {
    enabled: true,
    skipDiacritics: true,
    placeholder: "nhập gì đó..."
  };
  private sortOptions: any = {
    enabled: true
  };
  private paginationOptions: any = {
    enabled: true,
    perPage: 5,
    position: "bottom",
    setCurrentPage: 1,
    perPageDropdown: [5, 10, 20, 50, 100],
    jumpFirstOrLast: true,
    nextLabel: this.$t("common.table.next"),
    prevLabel: this.$t("common.table.prev"),
    firstLabel: "Đầu",
    lastLabel: "Cuối",
    rowsPerPageLabel: "Dòng/trang",
    ofLabel: "trên",
    pageLabel: "trang",
    allLabel: "Tất cả"
  };

  //
  private contractId: string = "";

  //filter
  private tableFilter: any = {
    company: "",
    supplier: "",
    port: "",
    origin: "",
    sortBy: "",
    sortType: ""
  };

  //role
  private userRole: UserRole = this.$store.state.user.role;
  private eUserRole = UserRole;

  created() {
    this.loadTableNewFilter();
    this.setTableColumn();
    this.getListContract();
    this.$bus.$on("createContract", () => {
      this.getListContract();
    });
    this.$bus.$on("loadTableNewFilter", () => {
      this.loadTableNewFilter();
      this.setTableColumn();
    });
  }

  loadTableNewFilter() {
    if (sessionStorage.getItem("table-new-filter")) {
      this.tableFilter = JSON.parse(
        sessionStorage.getItem("table-new-filter") as string
      );
      if (this.tableFilter.sortBy && this.tableFilter.sortType) {
        this.sortOptions.initialSortBy = {
          field: this.tableFilter.sortBy,
          type: this.tableFilter.sortType
        };
      }

      if (this.tableFilter.perPage) {
        this.paginationOptions.perPage = this.tableFilter.perPage;
      }

      if (this.tableFilter.page) {
        this.paginationOptions.setCurrentPage = this.tableFilter.page;
      }
    } else {
      this.tableFilter = {
        company: "",
        supplier: "",
        port: "",
        origin: "",
        sortBy: "",
        sortType: ""
      };
    }
  }

  destroyed() {
    this.$bus.$off("createContract");
    this.$bus.$off("loadTableNewFilter");
  }

  @Watch("isLoading")
  setTrackingRows() {
    setTimeout(() => {
      clearTimeout();
      (this.$refs
        .tableContractNew as any).currentPage = this.paginationOptions.setCurrentPage;
    }, 1);
  }

  /**
   * Call API get list contract
   */
  async getListContract() {
    return ContractService.getContractsNew()
      .then(res => {
        if (res.status === 200) {
          this.rows = res.data.map((item: any) => new ContractViewModel(item));
          this.isLoading = false;
        }
      })
      .catch(err => {
        console.log(err);
      });
  }

  setTableFilter() {
    sessionStorage.setItem(
      "table-new-filter",
      JSON.stringify(this.tableFilter)
    );
  }

  onSortChange(params: any) {
    this.tableFilter.sortBy = params[0].field;
    this.tableFilter.sortType = params[0].type;
    this.setTableFilter();
  }

  onPageChange(params: any) {
    this.tableFilter.page = params.currentPage;
    this.tableFilter.perPage = params.currentPerPage;
    this.setTableFilter();
  }

  onColumnFilter(params: any) {
    this.tableFilter.company = params.columnFilters.company;
    this.tableFilter.supplier = params.columnFilters.supplierCode;
    this.tableFilter.port = params.columnFilters.port;
    this.tableFilter.origin = params.columnFilters.origin;
    this.setTableFilter();
  }

  /**
   * Config comlumn
   */
  @Watch("listCompany")
  @Watch("listSupplier")
  @Watch("listOrigin")
  @Watch("listPort")
  setTableColumn() {
    const col1 = [
      {
        label: "#",
        field: "id",
        hidden: true
      },
      {
        label: "Ngày ký",
        field: "timeSigned",
        width: "100px",
        sortFn: this.onSortDate
      },
      {
        label: "CT",
        tooltip: "Công ty",
        field: "company",
        width: "80px",
        filterOptions: {
          enabled: true,
          filterDropdownItems: this.listCompany.map(item => item.code),
          filterValue: this.tableFilter.company,
          placeholder: "Tất cả",
          styleClass: "contract-table-filter-select p-1",
          filterFn: (data: string, filterString: string) => {
            return data === filterString;
          }
        },
        sortable: false
      },
      {
        label: "NCC",
        tooltip: "Nhà cung cấp",
        field: "supplierCode",
        width: "150px",
        filterOptions: {
          enabled: true,
          filterDropdownItems: this.listSupplier.map(item => item.code).sort(),
          filterValue: this.tableFilter.supplier,
          placeholder: "Tất cả",
          styleClass: "contract-table-filter-select p-1",
          filterFn: (data: string, filterString: string) => {
            return data === filterString;
          }
        },
        sortable: false
      },
      {
        label: "Mã HĐ",
        tooltip: "Mã hợp đồng",
        width: "200px",
        field: "code"
      },
      {
        label: "Cảng",
        field: "port",
        width: "80px",
        filterOptions: {
          enabled: true,
          filterDropdownItems: this.listPort.map(item => item.code).sort(),
          filterValue: this.tableFilter.port,
          placeholder: "Tất cả",
          styleClass: "contract-table-filter-select p-1",
          filterFn: (data: string, filterString: string) => {
            return data === filterString;
          }
        },
        sortable: false
      },
      {
        label: "GP",
        tooltip: "Giấy phép",
        field: "license",
        width: "70px"
      },
      {
        label: "TT",
        tooltip: "Thanh toán",
        field: "paymentMethod",
        width: "70px"
      },
      {
        label: "XX",
        tooltip: "Xuất xứ",
        field: "origin",
        width: "80px",
        filterOptions: {
          enabled: true,
          filterDropdownItems: this.listOrigin.map(item => item.code).sort(),
          filterValue: this.tableFilter.origin,
          placeholder: "Tất cả",
          styleClass: "contract-table-filter-select p-1",
          filterFn: (data: string, filterString: string) => {
            return data === filterString;
          }
        },
        sortable: false
      },
      {
        label: "Mặt hàng",
        field: "productsVM",
        tdClass: "mw--50",
        sortable: false
      },
      {
        label: "ĐGHQ",
        tooltip: "đơn giá hải quan",
        field: "valueTempListVM",
        width: "120px",
        tdClass: "text-align-end",
        sortFn: this.onSortNumber
      }
    ];

    // if (this.userRole !== UserRole.ACCOUNTANT) {
    //   col1.push({
    //     label: "ĐGTT",
    //     tooltip: "đơn giá thực tế",
    //     field: "priceTempListVM",
    //     width: "120px",
    //     tdClass: "text-align-end",
    //     sortFn: this.onSortNumber
    //   });
    // }

    const col2 = [
      {
        label: "Tổng",
        field: "total",
        width: "120px",
        tdClass: "text-align-end"
      },

      {
        label: "",
        field: "action",
        width: "50px",
        tdClass: "sticky-column",
        thClass: "sticky-column",
        sortable: false
      }
    ];

    this.columns = [...col1, ...col2];
  }

  handleRowNotice(row: any) {
    this.selectedNewContact = row;
    if (
      this.userRole === UserRole.GUEST ||
      this.userRole === UserRole.ACCOUNTANT
    )
      return;
    this.$bvModal.show("list-contract-new-notice");
  }

  formatTotal(total: number) {
    return new Intl.NumberFormat("currency").format(total);
  }

  submitNewNotice(newNotice: string) {
    ContractService.updateContractNotice(
      this.selectedNewContact.id,
      newNotice
    ).then(res => {
      if (res.status === 200) {
        //toast
        this.$swal.fire({
          icon: "success",
          title: "Đổi thành công",
          position: "bottom-end",
          toast: true,
          showConfirmButton: false,
          timer: 1500
        });

        //update table data
        this.rows[this.selectedNewContact.originalIndex].notice = newNotice;
      }
    });
  }

  syncScroll(event: any, index: number) {
    const products: any = this.$refs[`productsVM${index}`];
    const valueTemp: any = this.$refs[`valueTempListVM${index}`];
    // const priceTemp: any = this.$refs[`priceTempListVM${index}`];
    products.scrollTop = event.target.scrollTop;
    valueTemp.scrollTop = event.target.scrollTop;
    // priceTemp.scrollTop = event.target.scrollTop;
  }

  setContractReady(row: ContractViewModel) {
    this.selectedNewContact = row;
    ContractService.setContractReady(row.id).then(() => {
      this.getListContract();
      this.$emit("exacContract");
    });
  }

  handleRowAction(row: ContractViewModel, type: ActionType) {
    this.selectedNewContact = row;
    switch (type) {
      case ActionType.DOCUMENT:
        this.$emit("openDocument", { contractId: row.id });
        break;
      case ActionType.UPDATE:
        ContractService.getContractById(this.selectedNewContact.id).then(
          res => {
            this.selectedContract = res.data;
            setTimeout(() => this.$bvModal.show("modal-edit-contract"), 10);
          }
        );
        break;
      case ActionType.DELETE:
        if (this.userRole !== UserRole.ADMIN) return;
        this.$swal({
          icon: "question",
          title: "Bạn thực sự muốn xóa hợp đồng này?",
          showCancelButton: true,
          showConfirmButton: true,
          confirmButtonText: "Xóa",
          cancelButtonText: "Hủy"
        }).then(result => {
          if (result.isConfirmed) {
            ContractService.deleteByContractId(row.id).then(res => {
              //toast
              this.$swal.fire({
                icon: "success",
                title: "Đã xóa",
                position: "bottom-end",
                toast: true,
                showConfirmButton: false,
                timer: 1500
              });

              //update table data
              this.rows = this.rows.filter(
                (item: ContractViewModel, index: number) =>
                  index !== row.originalIndex
              );
            });
          }
        });
        break;
    }
  }

  openModalContractDetail(contractId: any) {
    this.contractId = contractId;
    this.$bvModal.show("modal-contract-detail-list-new");
  }

  getSupplierInfo(code: string) {
    const finder = this.listSupplier.find(item => item.code === code);
    return finder ? finder : new CommonData();
  }

  onSortDate(x: any, y: any, col: any, rowX: any, rowY: any) {
    let xVal = this.$moment(x, '"DD MMM YY"');
    let yVal = this.$moment(y, '"DD MMM YY"');
    if (!xVal.isValid() && !yVal.isValid()) return 0;
    if (!xVal.isValid()) return -1;
    if (!yVal.isValid()) return 1;
    return xVal.isBefore(yVal) ? -1 : !xVal.isBefore(yVal) ? 1 : 0;
  }

  onSortNumber(x: string, y: string, col: any, rowX: any, rowY: any) {
    if (!x && !y) return 0;
    if (!x) return -1;
    if (!y) return 1;
    const xVal = Number(x.replaceAll(",", ""));
    const yVal = Number(y.replaceAll(",", ""));
    return xVal < yVal ? -1 : xVal > yVal ? 1 : 0;
  }

  toggleDropdown(id: number) {
    document
      .getElementById(`contract-id-${id}`)
      ?.parentElement?.setAttribute(
        "style",
        `z-index: ${this.zIndex} !important;`
      );
    this.zIndex++;
  }
}

enum ActionType {
  DOCUMENT,
  UPDATE,
  COMPLETE,
  DELETE
}
