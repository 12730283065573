

















































































































import { Component, Vue } from "vue-property-decorator";
import ModalCreateContract from "@/views/Contract/ModalCreate/index.vue";
import ListContractNew from "@/views/Contract/ListContract/ListContractNew.vue";
import CommonService from "@/services/CommonService";
import ModalDocument from "@/components/Modal/ModalDocument.vue";
import ListContractDone from "@/views/Contract/ListContract/ListContractDone.vue";
import ListContractProgress from "@/views/Contract/ListContract/ListContractProgress.vue";
import {
  BillFilter,
  BillStatus,
  BillViewModel,
  CommonData,
  UserRole
} from "@/models";
import BillService from "@/services/BillService";
import DatePickerLang from "@/helpers/DatePickerLang";
import ContractService from "@/services/ContractService";

@Component({
  components: {
    ModalCreateContract,
    ListContractNew,
    ModalDocument,
    ListContractDone,
    ListContractProgress
  }
})
export default class ContractPage extends Vue {
  private listCompany: CommonData[] = [];
  private listSupplier: CommonData[] = [];
  private listOrigin: CommonData[] = [];
  private listPort: CommonData[] = [];
  private listCarrier: CommonData[] = [];
  private selectedContractId: number = -1;
  private selectedBillId: number = -1;

  //tracking
  private isLoadingProgress: boolean = true;
  private rowsProgress: BillViewModel[] = [];
  private isLoadingDone: boolean = true;
  private rowsDone: BillViewModel[] = [];

  //filter
  private datePickProp: DatePickerLang = new DatePickerLang();
  private filterOption: BillFilter = new BillFilter({
    etaFrom: this.$moment()
      .add(-3, "M")
      .startOf("month")
      .toDate(),
    etaTo: this.$moment().toDate()
  });

  //role
  private userRole: UserRole = this.$store.state.user.role;
  private eUserRole = UserRole;

  created() {
    this.loadContractDoneFilter();
    this.getListCompany();
    this.getListSupplier();
    this.getListCountry();
    this.getListPort();
    this.getListCarrier();
    this.getListBillProgress();
    this.getListBillDone();
  }

  loadContractDoneFilter() {
    if (sessionStorage.getItem("done-eta-filter")) {
      const filter = JSON.parse(
        sessionStorage.getItem("done-eta-filter") as string
      );
      filter.etaFrom = this.toDateOrNull(filter.etaFrom, true);
      filter.etaTo = this.toDateOrNull(filter.etaTo);

      this.filterOption = filter;
    }
  }

  toDateOrNull(date: Date | undefined, isFrom?: boolean) {
    const ifNull = isFrom
      ? this.$moment()
          .add(-3, "M")
          .startOf("month")
          .toDate()
      : this.$moment().toDate();
    return date ? this.$moment(date).toDate() : ifNull;
  }

  listProgressReCall() {
    this.getListBillProgress();
    this.getListBillDone();
  }

  exacContract() {
    this.getListBillProgress();
    (this.$refs as any).listContractProgress.$el.scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "center"
    });
  }

  /**
   * API get list bill
   */
  getListBillProgress() {
    this.rowsProgress = [];
    this.getContractNoBill();
    BillService.getLstData(new BillFilter())
      .then(res => {
        if (res.status === 200) {
          const data = res.data.map((item: any) => new BillViewModel(item));
          this.rowsProgress = [...this.rowsProgress, ...data];
        }
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => (this.isLoadingProgress = !this.isLoadingProgress));
  }

  getContractNoBill() {
    ContractService.getContractsNoBill()
      .then(res => {
        const data = res.data.map((item: any) => new BillViewModel(item));
        this.rowsProgress = [...data, ...this.rowsProgress];
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => (this.isLoadingProgress = !this.isLoadingProgress));
  }

  /**
   * API get list bill
   */
  getListBillDone() {
    const from = this.filterOption.etaFrom
      ? this.$moment(this.filterOption.etaFrom).format("DD-MM-YYYY 00:00:00")
      : null;

    const to = this.filterOption.etaTo
      ? this.$moment(this.filterOption.etaTo).format("DD-MM-YYYY 23:59:99")
      : null;

    const filter = { ...this.filterOption };
    from ? "" : (filter.etaFrom = undefined);
    to ? "" : (filter.etaTo = undefined);

    sessionStorage.setItem("done-eta-filter", JSON.stringify(filter));

    const filterOption = new BillFilter({
      etaFrom: from,
      etaTo: to,
      done: true,
      process: false
    });

    BillService.getLstData(filterOption)
      .then(res => {
        if (res.status === 200) {
          this.rowsDone = res.data.map((item: any) => new BillViewModel(item));
          this.rowsDone.reverse();
        }
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => (this.isLoadingDone = !this.isLoadingDone));
  }

  /**
   * call API get list company
   */
  async getListCompany() {
    return CommonService.getAllCompany()
      .then(res => {
        if (res.status === 200) {
          this.listCompany = res.data;
        }
      })
      .catch(err => {
        console.log(err);
      });
  }

  /**
   * call API get list country
   */
  async getListCountry() {
    return CommonService.getAllCountry()
      .then(res => {
        if (res.status === 200) {
          this.listOrigin = res.data;
        }
      })
      .catch(err => {
        console.log(err);
      });
  }

  /**
   * call API get list supplier
   */
  async getListSupplier() {
    return CommonService.getAllSupplier()
      .then(res => {
        if (res.status === 200) {
          this.listSupplier = res.data;
        }
      })
      .catch(err => {
        console.log(err);
      });
  }

  /**
   * call API get list port
   */
  async getListPort() {
    return CommonService.getAllPort()
      .then(res => {
        if (res.status === 200) {
          this.listPort = res.data;
        }
      })
      .catch(err => {
        console.log(err);
      });
  }

  /**
   * Call API get all ports
   */
  getListCarrier() {
    CommonService.getAllCarrier()
      .then(res => {
        if (res.status === 200) {
          this.listCarrier = res.data;
        }
      })
      .catch(err => {
        console.log(err);
      });
  }

  clearContractFilter() {
    this.filterOption = new BillFilter({
      etaFrom: this.$moment()
        .add(-3, "M")
        .startOf("month")
        .toDate(),
      etaTo: this.$moment().toDate()
    });
    sessionStorage.removeItem("table-new-filter");
    sessionStorage.removeItem("table-progress-filter");
    sessionStorage.removeItem("table-done-filter");
    sessionStorage.removeItem("done-eta-filter");

    //reset filter
    this.$bus.$emit("loadTableNewFilter");
    this.$bus.$emit("loadTableProgressFilter");
    this.$bus.$emit("loadTableDoneFilter");

    //recall list contract new
    this.$bus.$emit("createContract");
    //recall bill
    this.listProgressReCall();
  }

  openDocument(contractBill: { contractId: number; billId?: number }) {
    this.selectedContractId = contractBill.contractId;
    this.selectedBillId = contractBill.billId ? contractBill.billId : -1;
    setTimeout(() => {
      this.$bvModal.show("modal-document-list-contract");
    }, 10);
  }
}
