import Const from '../constants'
import axiosApiInstance from '@/interceptors'
import { VetAndClear } from '@/models'

class BillService {
  getLstData(filter: any) {
    return axiosApiInstance.post(Const.api_url + '/bill', filter)
  }

  updateBillNotice(billId: number, notice: string) {
    return axiosApiInstance.post(Const.api_url + `/bill/${billId}/notice`, { notice: notice })
  }

  deleteProgressBill(billId: number) {
    return axiosApiInstance.delete(Const.api_url + `/bill/delete/${billId}`)
  }

  vetCheck(vetAndClear: VetAndClear) {
    return axiosApiInstance.put(Const.api_url + '/bill/check', vetAndClear)
  }

  setBillDone(billId: number) {
    return axiosApiInstance.post(Const.api_url + `/bill/${billId}/done`)
  }

  getAllCalendar() {
    return axiosApiInstance.get(Const.api_url + `/bill/schedule`)
  }
}

export default new BillService